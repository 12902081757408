import RequestQueryBuilder from './RequestQueryBuilder';
import _, {isEmpty} from 'lodash';
import moment from 'moment';
import {CallStatus} from "ucassistant/models/CallHistory.model";

export default function XucCallHistory($rootScope, $interval, $q, $log, XucLink, XucPhoneEventListener, remoteConfiguration, XucPhoneHintService, XucVideoEventManager, Broadcaster) {
  var callHistory = [];
  var rawHistory = [];
  var _initDefer = $q.defer();
  var _pendingCustomerFindRequests = {};
  var _lastCustomerFindRequestId = 0;
  var _userCallHistoryNbOfDays = 7;
  var subscribed = false;  

  var _whenLoaded = function() { return _initDefer.promise; };

  var _queryBuilder = function() {
    return new RequestQueryBuilder();
  };

  var _updateUserCallHistory = function() {
    if (subscribed) {
      remoteConfiguration.getInt("callHistoryNbOfDays").then((value) => {
        _userCallHistoryNbOfDays = value;
        Cti.getUserCallHistoryByDays(_userCallHistoryNbOfDays);
      });
    }
  };

  var _updateAgentCallHistory = function(amountOfLines = 20) {
    if (subscribed) {
      Cti.getAgentCallHistory(amountOfLines);
    }
  };

  var _getCallHistory = function () {
    return callHistory;
  };

  var _getRawCallHistory = function () {
    return rawHistory;
  };

  var _processCallHistory = function(newHistory) {
    var isOngoingCall = _processCallHistoryItem(newHistory, callHistory);
    if (isOngoingCall) {
      $interval(_updateUserCallHistory, 2000, 1);
    }
  };

  var _processCallHistoryItem = function(newHistory, callHistory2) {
    var _callHistory = [];
    var lastDay = "";
    var index = -1;
    var ongoingCallFound = false;

    _.forEach(newHistory, function(item) {
      
      if (item.status == 'ongoing') {
        ongoingCallFound = true;
      }
    
      if (item.status == 'emitted') {
        item.number = item.dstNum;
        item.firstName = item.dstFirstName;
        item.lastName = item.dstLastName;
      } else {
        item.number = item.srcNum;
        item.firstName = item.srcFirstName;
        item.lastName = item.srcLastName;
      }
      
      if (item.number === null) {
        return ;
      }

      var day = moment(item.start).startOf('day').toISOString();
      if (lastDay !== day) {
        index++;
        lastDay = day;
        _callHistory[index] = {
          day: day,
          details: []
        };
      }
      _callHistory[index].details.push(item);
    });

    callHistory2 = _callHistory;
    callHistory = callHistory2;
    rawHistory = newHistory;
    if (!$rootScope.$$phase) $rootScope.$apply($rootScope.$broadcast('CallHistoryUpdated', callHistory));
    return ongoingCallFound;
  };

  var _processCustomerCallHistory = function(resp) {
    if(angular.isDefined(_pendingCustomerFindRequests[resp.id])) {
      var defer = _pendingCustomerFindRequests[resp.id];
      delete _pendingCustomerFindRequests[resp.id];
      var r = resp.response;

      var _callHistory = [];
      var lastDay = "";
      var index = -1;

      _.forEach(r.list, (item) => {
        var day = moment(item.start).startOf('day').toISOString();
        if (lastDay !== day) {
          index++;
          lastDay = day;
          _callHistory[index] = {
            day: day,
            details: []
          };
        }

        _callHistory[index].details.push(item);
      });
      defer.resolve(_callHistory);
    }
  };

  var _findCustomerCallHistoryAsync = function(query) {
    return _whenLoaded().then(function() {
      _lastCustomerFindRequestId++;
      var rqId = _lastCustomerFindRequestId;
      var defer = $q.defer();
      Cti.findCustomerCallHistory(rqId, query.filters, query.limit);
      _pendingCustomerFindRequests[rqId] = defer;
      return defer.promise;
    });
  };

  var _processPhoneStateReleased = function () {
    setTimeout(function () { remoteConfiguration.isAgent() ? _updateAgentCallHistory() : _updateUserCallHistory(); }, 3000);
  };

  var _subscribeToUserCallHistory = function(scope, callback) {
    _subscribeToCallHistory(scope, callback);
  };

  var _subscribeToAgentCallHistory = function(scope, callback) {
    _subscribeToCallHistory(scope, callback);
  };

  var _subscribeToCallHistory = function (scope, callback) {
    if (!subscribed) {
      subscribed = true;
      var unregister = $rootScope.$on('CallHistoryUpdated', function(event, updatedCallHistory) {
        if (!$rootScope.$$phase) scope.$apply(callback(updatedCallHistory));
      });
      scope.$on('$destroy', unregister);
    }
  };

  var _unSubscribeToCallHistory = function() {
    subscribed = false;
  };

  var _onPhoneHint = function(phoneHint) {
    let matchFound = false;

    if(isEmpty(callHistory)) {
      return;
    }

    rawHistory.forEach(elem => {
      switch (elem.status) {
      case CallStatus.Emitted:
        if (elem.dstNum === phoneHint.number && elem.dstPhoneStatus !== phoneHint.status) {
          elem.dstPhoneStatus = phoneHint.status;
          matchFound = true;
        }
        break;
      default:
        if (elem.srcNum === phoneHint.number && elem.srcPhoneStatus !== phoneHint.status) {
          elem.srcPhoneStatus = phoneHint.status;
          matchFound = true;
        }
      }

    });

    if(matchFound) {
      Broadcaster.send("CallHistoryUpdated");
    }
  };
  var _onVideoEvent = function(videoHint) {
    let matchFound = false;

    if(isEmpty(callHistory)) {
      return;
    }

    rawHistory.forEach(elem => {
      switch (elem.status) {
      case CallStatus.Emitted:
        if (elem.dstNum === videoHint.fromUser && elem.dstUserName !== videoHint.status) {
          elem.dstVideoStatus = videoHint.fromUser;
          matchFound = true;
        }
        break;
      default:
        if (elem.srcNum === videoHint.fromUser && elem.srcUserName !== videoHint.status) {
          elem.srcVideoStatus = videoHint.fromUser;
          matchFound = true;
        }
      }

    });

    if(matchFound) {
      Broadcaster.send("CallHistoryUpdated");
    }
  };
  var _unInit = function() {
    $log.info('Unloading CallHistory service');
    _initDefer.reject();
    _initDefer = $q.defer();
    callHistory = [];
    _pendingCustomerFindRequests = {};
    _lastCustomerFindRequestId = 0;
    XucLink.whenLogged().then(_init);
  };

  var _init = function() {
    $log.info('Starting CallHistory service');
    _initDefer.resolve();
    XucLink.whenLoggedOut().then(_unInit);
  };

  Cti.setHandler(Cti.MessageType.CALLHISTORY, _processCallHistory);
  Cti.setHandler(Cti.MessageType.RICHCALLHISTORY, _processCallHistory);
  Cti.setHandler(Cti.MessageType.CUSTOMERCALLHISTORY, _processCustomerCallHistory);

  XucPhoneEventListener.addReleasedHandler($rootScope, _processPhoneStateReleased);
  XucPhoneHintService.addEventListener($rootScope, _onPhoneHint);
  XucVideoEventManager.subscribeToVideoStatusEvent($rootScope,_onVideoEvent);

  XucLink.whenLogged().then(_init);

  return {
    updateUserCallHistory: _updateUserCallHistory,
    subscribeToUserCallHistory: _subscribeToUserCallHistory,
    unSubscribeToUserCallHistory: _unSubscribeToCallHistory,
    updateAgentCallHistory: _updateAgentCallHistory,
    subscribeToAgentCallHistory: _subscribeToAgentCallHistory,
    unSubscribeToAgentCallHistory: _unSubscribeToCallHistory,
    findCustomerCallHistoryAsync: _findCustomerCallHistoryAsync,
    queryBuilder: _queryBuilder,
    getCallHistory: _getCallHistory,
    getRawCallHistory: _getRawCallHistory,
  };
}
