import angular from "angular";

export default function XucDirectory($rootScope, XucPhoneHintService, XucLink, $log, $q, XucVideoEventManager, Broadcaster) {
  var searchResult = [];
  var favorites = [];
  var headers = [];
  var searchTerm = null;
  var isSearching = false;
  var displayNameRequests = new Map();
  var displayNamesCache = new Map();

  var _directoryLookup = function(term) {
    searchTerm = term ? term : '';
    isSearching = true;
    Cti.directoryLookUp(searchTerm);
  };

  var _getSearchTerm = function() {
    return searchTerm;
  };

  var _isSearching = function() {
    return isSearching;
  };

  var _onSearchResult = function(result) {
    searchResult = angular.copy(_removeFullnameDuplicates(result.entries));
    isSearching = false;
    _setHeaders(result);
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _onFavorites = function(newFavorites) {
    searchResult = [];
    favorites = angular.copy(_removeFullnameDuplicates(newFavorites.entries));
    _setHeaders(newFavorites);
    $rootScope.$broadcast('favoritesUpdated');
  };

  var _removeFullnameDuplicates = function (entries) {
    return entries.filter(
      (value, index, array) => {
        return array.findIndex(value2 => {
          if (value.entry != undefined && value2.entry != undefined) return (value2.entry[0] == value.entry[0]);
          else return -1;
        }) == index;
      }
    );
  };

  var _onUserDisplayNameResult = function(result) {
    if (result.displayName) {
      if (displayNameRequests.has(result.userName)) {
        displayNamesCache.set(result.userName, result.displayName);
        displayNameRequests.get(result.userName).resolve(result.displayName);
        displayNameRequests.delete(result.userName);
      }
      else {
        $log.error("Unknown result received, no such username in the map", result.userName);
      }
    }
    else {
      $log.error("Error when retrieving display name ", result);
      displayNameRequests.forEach((values) => {
        values.reject('Failure');
      });
      displayNameRequests.clear();
    }
  };

  var _setHeaders = function(result) {
    headers = angular.copy(result.headers);
    if(headers.indexOf("Favoris") != -1) {
      headers.splice(-3, 2);
    }
  };

  var _onFavoriteUpdated = function(update) {
    if (update.action === "Removed" || update.action === "Added") {
      searchResult.forEach(function (elem) {
        if (elem.contact_id == update.contact_id && elem.source == update.source) {
          elem.favorite = ! elem.favorite;
        }
      });
    }
    else {
      $log.log("SERVER ERROR: Favorite update failed: ", update);
    }
    _updateFavorites(update);
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _updateFavorites = function(update) {
    if (update.action === "Removed") {
      var result = _find(favorites, update);
      if (result.index >= 0) {
        favorites.splice(result.index, 1);
      }
    }
    else if (update.action == "Added") {
      var newFavorite = _find(searchResult, update);
      if (newFavorite) {
        favorites.push(newFavorite.elem);
      }
    }
    $rootScope.$broadcast('favoritesUpdated');
  };

  var _find = function(contacts, update) {
    var result;
    contacts.forEach(function(elem, i) {
      if (elem.contact_id === update.contact_id && elem.source === update.source) {
        result = {'elem': elem, 'index': i};
      }
    });
    return result;
  };

  var _getSearchResult = function() {
    return searchResult;
  };

  var _getFavorites = function() {
    return favorites;
  };

  var _clearResults = function() {
    favorites = [];
    searchResult = [];
    searchTerm = null;
    isSearching = false;
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _getHeaders = function() {
    return headers;
  };

  var _onPhoneHint = function(phoneHint) {
    let source = null;
    let sourceEvent = "";
    let matchFound = false;

    if (!isEmpty(searchResult)) {
      source = searchResult;
      sourceEvent = 'searchResultUpdated';
    } 
    else if(!isEmpty(favorites)) {
      source = favorites;
      sourceEvent = 'favoritesUpdated';
    } else return;

    angular.forEach(source, function(contact) {
      if(angular.isDefined(contact) &&
         angular.isArray(contact.entry) &&
         contact.entry[1] === phoneHint.number)
      {
        contact.status = phoneHint.status;
        matchFound = true;
      }
    });

    if(matchFound) {
      Broadcaster.send(sourceEvent);
    }
  };

  var isEmpty = function(array) {
    return array.length == 0;
  };

  var _onVideoEvent = function(videoEvent) {
    let source = null;
    let sourceEvent = "";
    let matchFound = false;

    if (!isEmpty(searchResult)) {
      source = searchResult;
      sourceEvent = 'searchResultUpdated';
    } 
    else if(!isEmpty(favorites)) {
      source = favorites;
      sourceEvent = 'favoritesUpdated';
    } else return;

    angular.forEach(source, function(contact) {
      if(angular.isDefined(contact) &&
         angular.isArray(contact.entry) &&
         contact.username === videoEvent.fromUser)
      {
        contact.videoStatus = videoEvent.status;
        matchFound = true;
      }
    });

    if(matchFound) {
      Broadcaster.send(sourceEvent);
    }
  }; 

  var _getUserDisplayName = (user) => {
    var deferred = $q.defer();
    if (displayNamesCache.has(user)) {
      deferred.resolve(displayNamesCache.get(user));
    }
    else
    {
      displayNameRequests.set(user, deferred);
      Cti.displayNameLookup(user);
    }
    return deferred.promise;
  };

  XucPhoneHintService.addEventListener($rootScope, _onPhoneHint);
  XucVideoEventManager.subscribeToVideoStatusEvent($rootScope,_onVideoEvent);

  const _init = () => {
    $log.info("Starting XucDirectory service");
    Cti.setHandler(Cti.MessageType.DIRECTORYRESULT, _onSearchResult);
    Cti.setHandler(Cti.MessageType.FAVORITES, _onFavorites);
    Cti.setHandler(Cti.MessageType.FAVORITEUPDATED, _onFavoriteUpdated);
    Cti.setHandler(Cti.MessageType.USERDISPLAYNAME, _onUserDisplayNameResult);
    XucLink.whenLoggedOut().then(_unInit);
  };

  const _unInit = () => {
    $log.info("Unloading XucDirectory service");
    displayNameRequests.clear();
    displayNamesCache.clear();
    XucLink.whenLogged().then(_init);
  };

  XucLink.whenLogged().then(_init);

  return {
    directoryLookup: _directoryLookup,
    onSearchResult : _onSearchResult,
    onFavorites : _onFavorites,
    onFavoriteUpdated : _onFavoriteUpdated,
    getSearchResult : _getSearchResult,
    getFavorites : _getFavorites,
    clearResults: _clearResults,
    getHeaders : _getHeaders,
    getSearchTerm : _getSearchTerm,
    isSearching : _isSearching,
    getUserDisplayName: _getUserDisplayName,
    removeFullnameDuplicates: _removeFullnameDuplicates
  };
}
