"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginHelper = exports.AUTH_PROVIDER = void 0;
const XucLinkError_1 = require("xccti/services/XucLinkError");
var AUTH_PROVIDER;
(function (AUTH_PROVIDER) {
    AUTH_PROVIDER["XUC"] = "XUC";
    AUTH_PROVIDER["KERBEROS"] = "KERBEROS";
    AUTH_PROVIDER["CAS"] = "CAS";
    AUTH_PROVIDER["OPENID"] = "OPENID";
})(AUTH_PROVIDER = exports.AUTH_PROVIDER || (exports.AUTH_PROVIDER = {}));
class LoginHelper {
    constructor(configuration, xucLink, $timeout, $log, lastError) {
        this.requireCredentials = true;
        this.loginInProgress = false;
        this.autoLoginInProgress = false;
        this.retryAutoLoginInitialTimeout = 5;
        this.retryAutoLoginMaxTimeout = 60;
        this.disableAutoLogin = false;
        this.retryAutoLogin = false;
        this.retryAutoLoginAfter = this.retryAutoLoginInitialTimeout;
        this.retryAutoLoginCountdown = this.retryAutoLoginInitialTimeout;
        this.moduleName = "LoginHelper";
        this.getAuthToken = () => Promise.reject();
        this.tradeAuthToken = () => Promise.reject();
        this.postLogout = () => { };
        this.getKerberosToken = () => {
            return this.xucLink.getSsoCredentials();
        };
        this.getCasToken = () => {
            if (this.configuration.casServerUrl) {
                return this.xucLink.getCasToken(this.configuration.casServerUrl, this.phoneNumber);
            }
            else {
                return Promise.reject(this.makeError(XucLinkError_1.XucAuthErrorCode.InvalidConfiguration, "CAS server URL is not defined"));
            }
        };
        this.tradeCasTokenToXucToken = (token) => {
            if (this.configuration.casServerUrl) {
                return this.xucLink.tradeCasToken(this.configuration.casServerUrl, token, this.phoneNumber);
            }
            else {
                return Promise.reject(this.makeError(XucLinkError_1.XucAuthErrorCode.InvalidConfiguration, "CAS server URL is not defined"));
            }
        };
        this.getOpenIDToken = () => {
            if (this.configuration.openidServerUrl && this.configuration.openidClientId) {
                return this.xucLink.getOpenidToken(this.configuration.openidServerUrl, this.configuration.openidClientId, this.phoneNumber);
            }
            else {
                return Promise.reject(this.makeError(XucLinkError_1.XucAuthErrorCode.InvalidConfiguration, "OpendID server URL or client id is not defined"));
            }
        };
        this.tradeOpendIDTokenToXucToken = (token) => {
            return this.xucLink.tradeOpenidToken(token);
        };
        this.getXucCredentials = () => {
            let cred = this.xucLink.getStoredCredentials();
            if (cred) {
                return Promise.resolve(cred);
            }
            else {
                return Promise.reject(this.makeError(XucLinkError_1.XucAuthErrorCode.NoStoredCredentials));
            }
        };
        this.tradeXucToken = (token) => {
            this.$log.debug(this.moduleName, "Trading xuc token");
            return Promise.resolve(token);
        };
        this.postLogoutCas = () => {
            if (this.configuration.casServerUrl && this.configuration.casLogoutEnable) {
                return this.xucLink.logoutFromCas(this.configuration.casServerUrl);
            }
        };
        this.configuration = configuration;
        this.xucLink = xucLink;
        this.$timeout = $timeout;
        this.$log = $log;
        this.$log.info(this.moduleName, "Starting");
        this.xucLink.setRedirectToHomeUrl(false);
        if (this.configuration.useSso) {
            this.authProvider = AUTH_PROVIDER.KERBEROS;
            this.getAuthToken = this.getKerberosToken;
            this.tradeAuthToken = this.tradeXucToken;
            this.requireCredentials = false;
        }
        else if (this.configuration.casServerUrl !== undefined) {
            this.authProvider = AUTH_PROVIDER.CAS;
            this.getAuthToken = this.getCasToken;
            this.tradeAuthToken = this.tradeCasTokenToXucToken;
            this.postLogout = this.postLogoutCas;
            this.requireCredentials = false;
        }
        else if (this.configuration.openidServerUrl !== undefined) {
            this.authProvider = AUTH_PROVIDER.OPENID;
            this.getAuthToken = this.getOpenIDToken;
            this.tradeAuthToken = this.tradeOpendIDTokenToXucToken;
            this.requireCredentials = false;
        }
        else {
            this.authProvider = AUTH_PROVIDER.XUC;
            this.getAuthToken = this.getXucCredentials;
            this.tradeAuthToken = this.tradeXucToken;
        }
        this.$log.info(this.moduleName, "Authentication provider: " + this.authProvider);
        if (lastError != undefined) {
            this.onLoginError(this.makeError(lastError));
        }
    }
    autoLogin(phoneNumber) {
        this.$log.info(this.moduleName, "Automatic login attempt");
        if (this.configuration.requirePhoneNumber &&
            (phoneNumber == undefined || phoneNumber.length === 0)) {
            this.onLoginError(this.makeError(XucLinkError_1.XucAuthErrorCode.RequirePhoneNumber));
            return;
        }
        this.phoneNumber = phoneNumber;
        if (this.lastError != undefined) {
            switch (this.lastError.error) {
                case XucLinkError_1.XucAuthErrorCode.LinkClosed:
                    this.$log.warn(this.moduleName, "Link was closed, attempting relogin");
                    break;
                case XucLinkError_1.XucAuthErrorCode.Logout:
                    this.$log.warn(this.moduleName, "Manual logout, aborting autologin");
                    this.postLogout();
                    return;
                default:
                    this.$log.warn(this.moduleName, "Got error " + this.lastError.error + ", aborting autologin");
                    return;
            }
        }
        this.loginInProgress = true;
        this.autoLoginInProgress = true;
        this.cancelAutoLoginLater();
        this.clearLastError();
        this.xucLink.checkXucIsUp()
            .then(this.getAuthToken.bind(this))
            .then(this.tradeAuthToken.bind(this))
            .then(this.finalizeLogin.bind(this))
            .catch(this.onLoginError.bind(this));
    }
    clearCasTicket() {
        window.location.search = '';
    }
    logoutFromOpenid() {
        let oidcUrl = this.configuration.openidServerUrl;
        if (oidcUrl)
            this.xucLink.logoutFromOpenid(oidcUrl, this.phoneNumber);
    }
    onLoginError(error) {
        this.$log.error(this.moduleName, "onLoginError: " + error.error);
        this.loginInProgress = false;
        this.autoLoginInProgress = false;
        this.lastError = error;
        switch (error.error) {
            case XucLinkError_1.XucAuthErrorCode.NoStoredCredentials:
                this.clearLastError();
                this.fallbackToManualLogin();
                break;
            case XucLinkError_1.XucAuthErrorCode.LinkClosed:
            case XucLinkError_1.XucAuthErrorCode.NoResponse:
                this.retryAutoLoginLater();
                break;
            case XucLinkError_1.XucAuthErrorCode.Logout:
                if (this.configuration.openidLogoutEnable)
                    this.logoutFromOpenid();
                break;
            case XucLinkError_1.XucAuthErrorCode.RequirePhoneNumber:
            case XucLinkError_1.XucAuthErrorCode.LoggedInOnAnotherPhone:
                break;
            case XucLinkError_1.XucAuthErrorCode.CasServerInvalidTicket:
                this.clearCasTicket();
                break;
            default:
                this.fallbackToManualLogin();
                break;
        }
    }
    login(username, password, phoneNumber) {
        this.$log.info(this.moduleName, "Login attempt with credentials");
        this.loginInProgress = true;
        this.disableAutoLogin = true;
        this.cancelAutoLoginLater();
        this.clearLastError();
        if (this.configuration.requirePhoneNumber &&
            (phoneNumber == undefined || phoneNumber.length === 0)) {
            this.onLoginError(this.makeError(XucLinkError_1.XucAuthErrorCode.RequirePhoneNumber));
        }
        else {
            this.xucLink.login(username, password, phoneNumber)
                .catch(this.onLoginError.bind(this));
        }
    }
    fallbackToManualLogin() {
        this.$log.debug(this.moduleName, "Falling back to manual login");
        this.loginInProgress = false;
        this.autoLoginInProgress = false;
        this.requireCredentials = true;
        this.retryAutoLogin = false;
        this.authProvider = AUTH_PROVIDER.XUC;
        this.getAuthToken = this.getXucCredentials;
        this.tradeAuthToken = this.tradeXucToken;
    }
    finalizeLogin(credentials) {
        this.$log.info(this.moduleName, "Finalize login");
        if (this.phoneNumber) {
            credentials.phoneNumber = this.phoneNumber;
        }
        return this.xucLink.checkAndloginWithCredentials(credentials);
    }
    retryAutoLoginLater() {
        this.$log.debug(this.moduleName, "Retry autologin later");
        if (this.disableAutoLogin) {
            return;
        }
        if (!this.retryAutoLogin) {
            this.retryAutoLogin = true;
            this.retryAutoLoginAfter = this.retryAutoLoginInitialTimeout;
        }
        else {
            this.retryAutoLoginAfter = this.retryAutoLoginAfter * 2;
            if (this.retryAutoLoginAfter > this.retryAutoLoginMaxTimeout) {
                this.retryAutoLoginAfter = this.retryAutoLoginMaxTimeout;
            }
        }
        this.retryAutoLoginCountdown = this.retryAutoLoginAfter;
        this.$log.debug(this.moduleName, "Retry in " + this.retryAutoLoginCountdown);
        this.retryAutoLoginPromise = this.$timeout(this.updateAutoLoginCountdown.bind(this), 1000);
    }
    cancelAutoLoginLater() {
        if (this.retryAutoLoginPromise) {
            this.$timeout.cancel(this.retryAutoLoginPromise);
            this.retryAutoLoginPromise = undefined;
        }
    }
    ;
    updateAutoLoginCountdown() {
        this.retryAutoLoginCountdown--;
        this.$log.debug(this.moduleName, "Retry countdown " + this.retryAutoLoginCountdown);
        this.retryAutoLoginPromise = undefined;
        if (this.retryAutoLoginCountdown <= 0) {
            this.clearLastError();
            this.autoLogin();
        }
        else {
            this.retryAutoLoginPromise = this.$timeout(this.updateAutoLoginCountdown.bind(this), 1000);
        }
    }
    makeError(code, message) {
        return { error: code, message: message };
    }
    clearLastError() {
        this.lastError = undefined;
    }
    hasError() {
        return this.lastError != undefined;
    }
}
exports.LoginHelper = LoginHelper;
